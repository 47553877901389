import React from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Divider,
  Grid,
  Link as MUILink,
  Stack,
  Typography,
  IconButton
} from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
// import InstagramIcon from '@mui/icons-material/Instagram';
import { FaTiktok } from 'react-icons/fa6';
import config from '@configFile';
import colors from '@utils/colors';
import MailingListButton from './components/MailingListButton';

const iconButtonStyle = {
  color: 'white',
  '&:hover': { opacity: 0.7 }
};

// import FooterLinks from './components/FooterLinks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OldFooter = () => {
  return (
    <Box
      sx={{
        display: 'block',
        backgroundColor: colors.jdbPurple,
        color: 'white',
        padding: '20px',
        borderTop: '1px solid rgba(0,0,0,0.12)'
      }}
    >
      <Box
        sx={{
          maxWidth: {
            xs: '100%',
            sm: '100%',
            md: '100%',
            lg: '1360px',
            xl: '1360px'
          },
          margin: '0 auto',
          textAlign: 'right'
        }}
      >
        <Typography
          sx={{ fontSize: '16px', marginRight: '16px', marginTop: '16px' }}
        >
          &copy; {new Date().getFullYear()} {config.app.title}
        </Typography>
      </Box>
    </Box>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Footer = () => {
  const scrollToTop = () => {
    window?.scrollTo({
      behavior: 'smooth',
      top: 0
    });
  };

  return (
    <Box
      component="footer"
      sx={{
        maxWidth: '100%',
        // A light background to match BrickSeek style
        backgroundColor: colors.jdbPurple,
        borderTop: '1px solid',
        borderColor: (theme) =>
          theme.palette.mode === 'light' ? '#ddd' : theme.palette.divider,
        paddingX: { xs: 2, md: 6 },
        paddingY: { xs: 3, md: 4 },
        fontFamily: 'Roboto,Helvetica,Arial,sans-serif'
      }}
    >
      <Box
        sx={{
          maxWidth: {
            xs: '100%',
            sm: '100%',
            md: '100%',
            lg: '1360px',
            xl: '1360px'
          },
          margin: '0 auto',
          display: 'block'
        }}
      >
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ mb: { xs: 3, md: 4 } }}
        >
          <Grid item xs="auto">
            <MUILink
              variant="h5"
              href="/"
              sx={{
                fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
                fontWeight: 400,
                fontSize: '1.5rem',
                color: 'white'
              }}
            >
              {config.app.title}
            </MUILink>
          </Grid>

          <Grid
            item
            xs={12}
            md="auto"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: 3,
              mt: { xs: 2, md: 0 }
            }}
          >
            <MUILink
              component={Link}
              to="/amazon-coupon-deals"
              underline="none"
              onClick={scrollToTop}
              sx={{
                color: 'white',
                fontSize: '1rem',
                '&:hover': { textDecoration: 'underline' }
              }}
            >
              Amazon Coupons
            </MUILink>
            <MUILink
              component={Link}
              to="/blog"
              underline="none"
              sx={{
                color: 'white',
                fontSize: '1rem',
                '&:hover': { textDecoration: 'underline' }
              }}
              onClick={scrollToTop}
            >
              Blog
            </MUILink>
            <MUILink
              component={Link}
              to="/p/amazon-special-promotions"
              onClick={scrollToTop}
              underline="none"
              sx={{
                fontSize: '1rem',
                color: 'white',
                '&:hover': { textDecoration: 'underline' }
              }}
            >
              Amazon Promotions
            </MUILink>
          </Grid>

          {/* Big "Support Center" button (right on desktop) */}
          <Grid
            item
            xs="auto"
            sx={{
              ml: { xs: 'auto', md: 0 },
              mr: { xs: 'auto', md: 0 },
              mt: { xs: 2, md: 0 }
            }}
          >
            <MailingListButton />
          </Grid>
        </Grid>

        {/* Middle Section: Social icons + Underlined links */}
        <Box sx={{ mb: 3 }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            sx={{ mb: 2 }}
          >
            <IconButton
              aria-label="Facebook Page"
              href={config.FACEBOOK_LINK}
              target="_blank"
              rel="noopener"
              sx={iconButtonStyle}
            >
              <FacebookIcon />
            </IconButton>
            <IconButton
              aria-label="Facebook Group"
              // Insert real link
              href={config.FACEBOOK_GROUP_LINK}
              target="_blank"
              rel="noopener"
              sx={iconButtonStyle}
            >
              <FacebookIcon />
            </IconButton>
            <IconButton
              sx={iconButtonStyle}
              aria-label="TikTok"
              // Insert real link
              href={config.TIKTOK_LINK}
              target="_blank"
              rel="noopener"
            >
              <FaTiktok />
            </IconButton>
            {/* <IconButton
              sx={iconButtonStyle}
              aria-label="Instagram"
              href={config.INSTAGRAM_LINK}
              target="_blank"
              rel="noopener"
            >
              <InstagramIcon />
            </IconButton> */}
          </Stack>
        </Box>

        <Divider
          sx={{
            borderColor: '#ffffff99'
          }}
        />

        <Box sx={{ mt: 3 }}>
          <Typography
            variant="body2"
            sx={{
              textAlign: 'center',
              color: 'white',
              opacity: 0.7,
              maxWidth: 800,
              margin: '0 auto',
              mb: 2
            }}
          >
            {config.disclaimer}
          </Typography>

          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={{ xs: 1, md: 3 }}
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="caption"
              sx={{ color: 'white', fontSize: '0.8rem' }}
            >
              &copy; {new Date().getFullYear()} {config.app.title}
            </Typography>
            <MUILink
              href="https://www.jungle.deals/terms"
              target="_blank"
              underline="none"
              sx={{
                color: 'white',
                fontSize: '0.8rem',
                '&:hover': { textDecoration: 'underline' }
              }}
            >
              Terms of Service
            </MUILink>
            <MUILink
              href="https://www.jungle.deals/privacy-policy"
              target="_blank"
              underline="none"
              sx={{
                color: 'white',
                fontSize: '0.8rem',
                '&:hover': { textDecoration: 'underline' }
              }}
            >
              Privacy Policy
            </MUILink>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
