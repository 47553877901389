import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import Email from '@pages/Email/Email';

const MailingListButton = () => {
  const [signUpModalOpen, setSignUpModalOpen] = useState(false);
  return (
    <>
      <Button
        variant="contained"
        sx={{
          backgroundColor: '#3661da',
          borderRadius: '24px',
          textTransform: 'none',
          paddingX: 3,
          fontWeight: 600,
          '&:hover': {
            backgroundColor: '#2f57c5'
          }
        }}
        onClick={() => {
          setSignUpModalOpen(true);
          //   scrollToTop();
        }}
      >
        Mailing Lists
      </Button>
      <Dialog
        open={signUpModalOpen}
        onClose={() => setSignUpModalOpen(false)}
        aria-labelledby="sign-up-dialog-title"
        aria-describedby="sign-up-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="sign-up-dialog-title">Sign Up</DialogTitle>
        <DialogContent>
          <Email
            showPageContainer={false}
            signUpLocation="mailing-list-button"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSignUpModalOpen(false)} variant="outlined">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MailingListButton;
