import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  CircularProgress,
  Alert,
  Card,
  CardContent
} from '@mui/material';
import { formatDistance } from 'date-fns';
import { useGetSentNewsletters } from '@hooks/useSentNewsletters';
import { SentNewsletter } from '@api/database/models/sentNewsletter';

interface LastFiveMailingsProps {
  mailingListId: string;
}

export default function LastFiveMailings({
  mailingListId
}: LastFiveMailingsProps) {
  const [showMailings, setShowMailings] = useState(false);
  const [selectedMailing, setSelectedMailing] = useState<SentNewsletter | null>(
    null
  );

  const {
    data: mailings,
    isLoading,
    error,
    refetch
  } = useGetSentNewsletters(mailingListId);

  const handleViewMailings = () => {
    setShowMailings(true);
    refetch(); // triggers the network call
  };

  const handleClose = () => {
    setShowMailings(false);
    // Clear selected mailing if any
    setSelectedMailing(null);
  };

  return (
    <Box
      display="block"
      onClick={(e) => {
        e.stopPropagation();
      }}
      mt={2}
    >
      <Button size="small" variant="outlined" onClick={handleViewMailings}>
        View last 5 mailings
      </Button>

      <Dialog open={showMailings} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Last 5 Mailings</DialogTitle>
        <DialogContent dividers>
          {isLoading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <CircularProgress />
            </Box>
          )}

          {error && <Alert severity="error">Error loading mailings!</Alert>}

          {Array.isArray(mailings) && mailings.length === 0 && (
            <Typography>No mailings found.</Typography>
          )}

          {/* 
            Conditionally show:
            1) The MailingList if no mailing is selected
            2) The MailingDetail if we do have a selectedMailing
          */}
          {Array.isArray(mailings) &&
            mailings.length > 0 &&
            (selectedMailing ? (
              <MailingDetail
                mailing={selectedMailing}
                onBack={() => setSelectedMailing(null)}
              />
            ) : (
              <MailingList
                mailings={mailings}
                onSelectMailing={setSelectedMailing}
              />
            ))}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

/**
 * The "list" view of mailings (subjects & dates only).
 */
interface MailingListProps {
  mailings: SentNewsletter[];
  onSelectMailing: (mailing: SentNewsletter) => void;
}

function MailingList({ mailings, onSelectMailing }: MailingListProps) {
  return (
    <Box sx={{ mt: 1 }}>
      {mailings.map((mailing) => (
        <Card
          key={mailing.id}
          sx={{
            mb: 2,
            cursor: 'pointer',
            boxShadow: 3
          }}
        >
          <CardContent onClick={() => onSelectMailing(mailing)}>
            <Typography variant="subtitle1" gutterBottom>
              {mailing.subject}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {`Sent `}
              {formatDistance(new Date(mailing.createdAt), new Date(), {
                addSuffix: true
              })}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
}

/**
 * The "detail" view of a single mailing.
 * Shows the entire HTML content, plus a Back button.
 */
interface MailingDetailProps {
  mailing: SentNewsletter;
  onBack: () => void;
}

function removeUnsubscribeBlock(originalHtml: string) {
  // Regex that matches the entire block of unsubscribe content.
  // This is a naive example that looks for the
  // <div data-role="module-unsubscribe" ...> ... </div> pattern
  // It works best if that block doesn't have nested <div> with the same attribute
  // or other complexities.
  const test = originalHtml.replace(
    /<div data-role="module-unsubscribe"[\s\S]*?<\/div>/gi,
    ''
  );

  const test2 = test.replace(
    /<p[^>]*>\s*<a[^>]*class="Unsubscribe--unsubscribeLink"[\s\S]*?<\/p>/gi,
    ''
  );

  return test2.replace(
    /<p[^>]*>\s*<a[^>]*class="Unsubscribe--unsubscribePreferences"[\s\S]*?<\/p>/gi,
    ''
  );
}

function MailingDetail({ mailing, onBack }: MailingDetailProps) {
  // Remove the unsubscribe block from the HTML content
  const cleanedHtml = removeUnsubscribeBlock(mailing.html);

  return (
    <Box>
      <Button variant="outlined" sx={{ mb: 2 }} onClick={onBack}>
        Back to list
      </Button>

      <Typography variant="subtitle1" gutterBottom>
        {mailing.subject}
      </Typography>

      <Typography variant="body2" color="text.secondary" gutterBottom>
        {`Sent `}{' '}
        {formatDistance(new Date(mailing.createdAt), new Date(), {
          addSuffix: true
        })}
      </Typography>

      <Box
        sx={{ mt: 2 }}
        // Use caution with dangerouslySetInnerHTML:
        //  ideally sanitize or trust your email HTML only from safe sources
        dangerouslySetInnerHTML={{ __html: cleanedHtml }}
      />
    </Box>
  );
}
